import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import SEO from "../../components/seo";
import PropTypes from "prop-types";
import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import Hero from "../../components/Hero/Hero";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

export const BlogWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto 100px;
  h2 {
    font-size: 42px;
    padding-bottom: 8px;
    @media only screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
  h4 {
    margin-bottom: 8px;
  }
  p {
    padding-bottom: 20px;
  }
`;

export const BlogItem = styled.div`
  display: flex;
  background-color: #0f0654;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export const BlogText = styled.div`
  padding: 25px 30px;
  max-width: 590px;
  flex: 1;
`;

export const BlogImage = styled.div`
  display: flex;
  width: 425px;
  position: relative;
  max-width: 40%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    padding-bottom: 60%;
  }
`;

export const StyledBackgroundImage = styled(BackgroundImage)`
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const BlogPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout>
      <Hero
        smaller
        subtitle="All you need to know about what’s going on in the big, wide universe of digital products. If you’d like to learn more, just let us know"
      >
        THINGS WE'VE LEARNED
        <br />
        FROM THE OUTERWORLD
      </Hero>

      <BlogWrapper>
        {posts.map(({ node: post }, index) => (
          <BlogItem>
            <BlogImage>
              <StyledBackgroundImage
                fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
              />
            </BlogImage>
            <BlogText>
              <h4>{post.frontmatter.tags[0]}</h4>
              <h2>{post.frontmatter.title}</h2>
              <p>{post.frontmatter.description}</p>
              <Link to={post.fields.slug} key={post.id}>
                <Button transparent>READ MORE</Button>
              </Link>
            </BlogText>
          </BlogItem>
        ))}
      </BlogWrapper>
    </Layout>
  );
};

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogPageQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 425)
              id
              fields {
                slug
              }
              frontmatter {
                title
                tags
                description
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogPage data={data} count={count} />}
  />
);
